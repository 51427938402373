import React from "react";
import "./Footer.scss";
import footerMan from "../../../assets/JDK/bgJDK.png";
import footerMan1 from "../../../assets/footerIMG123.png";
import ScrollableAnchor from "react-scrollable-anchor";
import {langType} from "../lang";
const Footer = ({lang}) => {
  return (
    <React.Fragment>
      <img src={footerMan} alt="footerIMG" className="fotIMG2" />
      <div className="Footer">
        <ScrollableAnchor id={"Footer1"}>
          <div className="FooterContainer">
            <div className="FooterLinks">
              <h1>{langType[lang].header.t5}</h1>
              <p>
                {langType[lang].main.t1}
              </p>
              <h2>
                <a
                  className="tel-href"
                  href={
                    window.location.href.includes("/customers")
                      ? "tel:+375 (29) 863-20-30"
                      : "tel:+375 (29) 863-20-30"
                  }
                >
                  {window.location.href.includes("/customers")
                    ? "+375 (29) 863-20-30"
                    : "+375 (29) 863-20-30"}
                </a>
              </h2>
               <div className="Links">
                <div>
                <a className="hoverMail">jdk.orders@gmail.com</a>
                  <a href="https://www.wechat.com/ru/">WeChat: Manager_JDK</a>
                  {/* <a>+375 (29) 333–15–83</a> */}
                  <a
                    href={
                      window.location.href.includes("/customers")
                        ? "https://t.me/Manager_JDK"
                        : "https://t.me/Manager_JDK"
                    }
                    className="tg"
                  >
                    Telegram
                  </a>
                  <a
                    className="whatsapp"
                    href={
                      window.location.href.includes("/customers")
                        ? "https://wa.me/375298632030"
                        : "https://wa.me/375298632030"
                    }
                  >
                    Whatsapp
                  </a>
                </div>
                <div>
                  {" "}
                  <a
                    href={
                      window.location.href.includes("/customers")
                        ? "viber://chat?number=%2B375298632030"
                        : "viber://chat?number=%2B375298632030"
                    }
                    className="viber"
                  >
                    Viber
                  </a>
                   
                </div>
              </div>
            </div>
            <img src={footerMan} alt="footerIMG" className="fotIMG1" />{" "}
          </div>
        </ScrollableAnchor>{" "}
      </div>{" "}
      <div className="ip">ИП Райчёнок Кирилл Алексеевич </div>{" "}
      <div className="ip"> УНП 391542375</div>
    </React.Fragment>
  );
};

export default Footer;
