export const langType = {
  ru: {
    header: {
      t1: "Услуги",
      t2: "О нас",
      t3: "Этапы работы",
      t4: "Преимущества",
      t6: "Наши партнеры",
      t5: "Контакты",
    },
    main: {
      t1: "Оставьте заявку и мы вернемся с обратной связью в течение 30 минут",
      t2: "Запросить расчет",
    },
    services: {
      t1H: "предоставляем услуги",
      t2: "Автомобильные перевозки",
      t5: "Перевозки комплектных грузов",
      t6: "Перевозки опасных грузов",
      t7: "Рефрижераторные перевозки",
      t8: "Перевозки сборных грузов",
      t9: "Перевозки негабаритных грузов",
      t10H: "География перевозок",
      t11: "Европа, Азия – Россия, Беларусь, Казахстан и др. страны СНГ (импорт, экспорт)",
      t111: "Внутри России",
      t12: "между странами Европы",
      t13: "между странами СНГ",
      t14H: "Виды грузов",
      t15: "Пищевая промышленность",
      t16: "Химическая промышленность",
      t17: "Оборудование и инструменты",
      t18: "Косметика и парфюмерия",
      t19: "Потребительские промтовары",
      t20H: "Комплексное обслуживание",
      t21: "Оптимизация логических схем",
      t22: "Документальное сопровождение",
      t23: "Страхование груза",
    },
    work: {
      t1H: "Как мы работаем",
      t1: "Оформление заявки на перевозку\n",
      t2: "Выбор и проверка поставщика\n",
      t3: "Передача заказа поставщику\n",
      t4: "Перевозка\n",
      t5: "Завершение перевозки",
      t6: "Запросить расчет",
    },
    back: {
      t1H: "Преимущества",
      t1: "1. Личный менеджер с большим опытом в международных грузоперевозках на каждый Ваш проект\n",
      t2: "2. Транспортировка небольших объемов продукции - возможность доставить пробную партию товара, испытать его, оценить качество, после чего решить, заказывать ли больше продукции",
      t3: "3. Выполняем погрузочно-разгрузочные работы на территории РБ",
      t4: "4. Высокий уровень лояльности - идем на встречу нашим клиентам\n",
    },
    cars: {
      t1: "Предоставляем технику",
      car1: "Рефрижератор",
      car11: "Габариты: 13,6x2,45x2,45-2,50\n",
      car12: "Грузоподъемность: 21,5 тонны\n",
      car13: "Объем: 82 м3\n",

      car2: "Тентованный полуприцеп (стандартный)\n",
      car21: "Габариты: 13,6x2,45x2,70",
      car23: "Грузоподъемность: 22 тонны\n",
      car24: "Объем: 86 м3 (82 - 92 м3)\n",

      car3: "Тентованный полуприцеп (MEGA)\n",
      car31: "Габариты: 13,6x2,45x3,0\n",
      car32: "Грузоподъемность: 22-23 тонны\n",
      car33: "Объем: 100 м3\n",

      car4: "Aвтопоезд 120 м3",
      car41: "Размер кузова: 8,05 м x 2,43 м x 3 м\n",
      car42: "Объем: 58,68 м3\n",
      car43: "Размеры прицепа: 8,05 м x 2,43 м x 3 м\n",
      car44: "Объем прицепа: 58,68 м3",

      car5: "Открытая платформа\n",
      car51: "Используется для перевозки негабаритных грузов\n",
      car52: "Высота: до 4,1 м\n",
      car53: "Длина: 18,1 м\n",
      car54: "Ширина: 2,55 м\n",

      car6: "Платформа для негабаритных грузов",
      car61: "Используется для перевозки негабаритных грузов",
      car62: "Высота: 3,71 м",
      car63: "Длина: 8,5-14,8 м\n",
      car64: "Ширина: 2,55 м\n",
    },
    popup: {
      t1: "Контакты",
      t3: "Запросить расчет",
      t2: "Оставьте заявку и мы перезвоним Вам в течение 30 минут\n",
    },
    dropdown: {
      title: "Выберите язык",
    },
    range: {
      t1: "О нас",
    },
    partner: "Наши Партнеры",
    quote:
      "Наша Миссия: Стремление стать компанией, которая привносит ценность и инновации в международную логистику.",
  },

  en: {
    header: {
      t1: "Services",
      t2: "About Us",
      t3: "Workflow Stages",
      t4: "Advantages",
      t6: "Our Partners",
      t5: "Contacts",
    },
    partner: "Our Customers",
    main: {
      t1: "Submit a transport enquiry and we will prepare a shipping quote promtly",
      t2: "Request a Quote",
    },
    services: {
      t1H: "Services We Provide",
      t2: "Road freight            ",
      t5: "FTL shipments  ",
      t6: "Dangerous goods (ADR) transportation      ",
      t7: "Temperature controlled deliveries       ",
      t8: " LTL shipments      ",
      t9: "Oversized cargo deliveries      ",
      t10H: "Geography of Transportation",
      t11: "Europe, Asia - Russia, Belarus, Kazakhstan, and other CIS countries",
      t111: "Inside Russia",
      t12: "Between European countries",
      t13: "Between CIS countries",
      t14H: "Types of Cargo",
      t15: "Food Industry",
      t16: "Chemical Industry",
      t17: "Equipment and Tools",
      t18: "Cosmetics and Perfumery",
      t19: "Consumer Goods",
      t20H: "Comprehensive Services",
      t21: "Optimization of Logical Schemes",
      t22: "Documentary Support",
      t23: "Cargo Insurance",
    },
    work: {
      t1H: "Steps to transportation with JDK",
      t1: "Processing a Transport Request",
      t2: "Supplier Selection and Verification",
      t3: "Transfer of Order to Supplier",
      t4: "Transportation",
      t5: "Completion of Transportation",
      t6: "Request a Quote",
    },
    back: {
      t1H: "Advantages",
      t1: "1. Personal manager experienced in international freight transportation for each of your projects",
      t2: "2. Transportation of small volumes of products - the ability to deliver a trial batch of goods, test them, evaluate the quality, and then decide whether to order more products",
      t3: "3. We carry out loading and unloading services in the territory of the Republic of Belarus",
      t4: "4. High level of loyalty - we accommodate our clients",
    },
    cars: {
      t1: "Equipment Provided",
      car1: "Refrigerated Truck",
      car11: "Dimensions: 13.6x2.45x2.45-2.50",
      car12: "Payload: 21.5 tons",
      car13: "Volume: 82 m³",

      car2: "Tarpaulin Semi-trailer (Standard)",
      car21: "Dimensions: 13.6x2.45x2.70",
      car23: "Payload: 22 tons",
      car24: "Volume: 86 m³ (82 - 92 m³)",

      car3: "Tarpaulin Semi-trailer (MEGA)",
      car31: "Dimensions: 13.6x2.45x3.0",
      car32: "Payload: 22-23 tons",
      car33: "Volume: 100 m³ ",

      car4: "Road trains 120CBM",
      car41: "Body Size: 8.05 m x 2.43 m x 3 m",
      car42: "Volume: 58.68 m³",
      car43: "Trailer Size: 8.05 m x 2.43 m x 3 m",
      car44: "Trailer Volume: 58.68 m³",

      car5: "Tiefbett      ",
      car51: "Used for transporting oversized cargo",
      car52: "Height: up to 4.1 m",
      car53: "Length: 18.1 m",
      car54: "Width: 2.55 m",

      car6: "Semitrailers    ",
      car61: "Used for transporting oversized cargo",
      car62: "Height: 3.71 m",
      car63: "Length: 8.5-14.8 m",
      car64: "Width: 2.55 m",
    },
    popup: {
      t1: "Contacts",
      t3: "Rate request",
      t2: "Leave a request and we will call you back within 30 minutes",
    },
    dropdown: {
      title: "Select Language",
    },
    range: {
      t1: "About Us",
    },
    quote:
      "Mission: Aspiration to become a company that brings value and innovation to the international logistics  ",
  },
  ch: {
    header: {
      t1: "服务",
      t2: "关于我们",
      t3: "工作流程",
      t4: "优势",
      t6: '我們的夥伴',
      t5: "联系方式",
    },
    partner: "我们的合作伙伴",
    main: {
      t1: "留下您的请求，我们将在30分钟内回复您",
      t2: "请求报价",
    },
    services: {
      t1H: "我们提供的服务",
      t2: "汽车运输",
      t5: "完整货物运输",
      t6: "危险货物运输",
      t7: "冷藏运输",
      t8: "拼箱货物运输",
      t9: "超大件货物运输",
      t10H: "运输地理范围",
      t11: "欧洲，亚洲 - 俄罗斯，白俄罗斯，哈萨克斯坦和其他独联体国家",
      t111: "俄羅斯境內",
      t12: "欧洲国家间",
      t13: "独联体国家间",
      t14H: "货物类型",
      t15: "食品工业",
      t16: "化工行业",
      t17: "设备和工具",
      t18: "化妆品和香水",
      t19: "消费品",
      t20H: "综合服务",
      t21: "优化逻辑方案",
      t22: "文件支持",
      t23: "货物保险",
    },
    work: {
      t1H: "我们的工作方式",
      t1: "处理运输请求",
      t2: "供应商选择和验证",
      t3: "将订单交付供应商",
      t4: "运输",
      t5: "完成运输",
      t6: "请求报价",
    },
    back: {
      t1H: "优势",
      t1: "1. 每个项目都有经验丰富的国际货运个人经理",
      t2: "2. 小型产品运输 - 对商人有利的服务。您有机会交付试验批货物，测试并评估质量，然后决定是否订购更多产品",
      t3: "3. 我们在白俄罗斯领土上提供装卸货服务",
      t4: "4. 高水平的忠诚度 - 我们顾及我们的客户",
    },
    cars: {
      t1: "提供的设备",
      car1: "冷藏卡车",
      car11: "尺寸：13.6x2.45x2.45-2.50",
      car12: "载重量：21.5吨",
      car13: "容积：82立方米",

      car2: "帆布半挂车（标准）",
      car21: "尺寸：13.6x2.45x2.70",
      car23: "载重量：22吨",
      car24: "容积：86立方米（82 - 92立方米）",

      car3: "帆布半挂车（MEGA）",
      car31: "尺寸：13.6x2.45x3.0",
      car32: "载重量：22-23吨",
      car33: "容积：100立方米",

      car4: "带单框架货车 + 拖车",
      car41: "车身尺寸：8.05米 x 2.43米 x 3米",
      car42: "容积：58.68立方米",
      car43: "拖车尺寸：8.05米 x 2.43米 x 3米",
      car44: "拖车容积：58.68立方米",

      car5: "开放式平台",
      car51: "用于运输超大件货物",
      car52: "高度：高达4.1米",
      car53: "长度：18.1米",
      car54: "宽度：2.55米",

      car6: "超大件货物平台",
      car61: "用于运输超大件货物",
      car62: "高度：3.71米",
      car63: "长度：8.5-14.8米",
      car64: "宽度：2.55米",
    },
    popup: {
      t1: "联系方式",
      t3: "请求报价",
      t2: "留下您的请求，我们将在30分钟内回复您",
    },
    dropdown: {
      title: "选择语言",
    },
    range: {
      t1: "关于我们",
    },
    quote: "我们的使命：努力成为一家为国际物流带来价值和创新的公司。",
  },
};
