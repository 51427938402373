import "./Card.scss";
import ScrollableAnchor from "react-scrollable-anchor";
import Bg from '../../../assets/JDK/range/jdk.png'
import {langType} from "../lang";
import {CardText, imagesArray} from "./cardText";

const Card = ({count, lang}) => {
    const component = (
        <div className={'cardContainer'}>
            <div className={'container'}>
                <span className={'text descr'}>{CardText[lang][count - 1]}</span>

                {/*<span className={'text number'}>{count}</span>*/}
                {/*<img className={'images'} src={imagesArray[count - 1]}/>*/}
            </div>
            {/*<span className={'text descr'}>{CardText[count - 1]}</span>*/}
        </div>
    )

    const initialComponent = (
        <div className={'cardContainer'}>
            <div className={'container'}>
                <img className={'imgfull'} src={Bg} alt={'bg'}/>
            </div>
        </div>
    )
    return +count === 0 ? initialComponent : component


};

export default Card;
