import React from "react";
import "./Header.scss";
import {langType} from "../lang";
import Dropdown from "../Dropdown/dropdown";

const Header = ({setLang,lang}) => {
    return (
        <div className="Header">
            <div className="headerContainer">
                <div className="anchorsContainer">
                    <a href="#services">{langType[lang].header.t1}</a>
                    <a href="#Portfolio">{langType[lang].header.t2}</a>
                    <a href="#workS">{langType[lang].header.t3}</a>
                    <a href="#BackToUs1">{langType[lang].header.t4}</a>
                    <a href="#Partners">{langType[lang].header.t6}</a>
                    <a href="#Footer1">{langType[lang].header.t5}</a>
                </div>
                <Dropdown setLang={setLang}  lang={lang}/>
                <div className="telContainer1">

                    <span>JDK</span>
                </div>
            </div>
        </div>
    );
};

export default Header;
