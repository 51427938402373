import "./Cars1.scss";
import {car1, car2, car3, car4, car5, car6, car7, car8} from "./carsObj";
import {langType} from "../lang";

const Cars = ({lang}) => {
    return (
        <div className="Cars1">
            <h3>{langType[lang].cars.t1}</h3>
            <div className="carsContainer">
                <div className="big-block">
                    <div className="block">
                        <div className="carBlock">
                            <h3>{langType[lang].cars.car1}</h3>
                            <div className="carImg1">
                                <img src={car1.img} className="img1CAR1" alt="car"/>
                            </div>
                            <div className={'textCont'}>
                            <p>{langType[lang].cars.car11}</p>
                            <p>{langType[lang].cars.car12}</p>
                            <p>{langType[lang].cars.car13}</p>
                            </div>
                        </div>
                        <div className="carBlock">
                            <h3>{langType[lang].cars.car2}</h3>

                            <div className="carImg1">
                                <img src={car2.img} className="img1CAR1" alt="car"/>
                            </div>
                            <div className={'textCont'}>
                            <p>{langType[lang].cars.car21}</p>
                            <p>{langType[lang].cars.car23}</p>
                            <p>{langType[lang].cars.car24}</p>
                            </div>
                        </div>
                    </div>
                    <div className="block">
                        <div className="carBlock tractor">
                            <h3>{langType[lang].cars.car3}</h3>
                            <div className="carImg1">
                                <img src={car3.img} className="img1CAR1" alt="car"/>
                            </div>
                            <div className={'textCont'}>
                            <p>{langType[lang].cars.car31}</p>
                            <p>{langType[lang].cars.car32}</p>
                            <p>{langType[lang].cars.car33}</p>
                            </div>
                        </div>

                        <div className="carBlock">
                            <h3>{langType[lang].cars.car4}</h3>
                            <div className="carImg1">
                                <img src={car4.img} className="img1CAR1" alt="car"/>
                            </div>
                            <div className={'textCont'}>
                            <p>{langType[lang].cars.car41}</p>
                            <p>{langType[lang].cars.car42}</p>
                            <p>{langType[lang].cars.car43}</p>
                            <p>{langType[lang].cars.car44}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="big-block">
                    <div className="block">
                        <div className="carBlock">
                            <h3>{langType[lang].cars.car5}</h3>
                            <div className="carImg1">
                                <img src={car5.img} className="img1CAR1" alt="car"/>
                            </div>
                            <div className={'textCont'}>
                                <p>{langType[lang].cars.car51}</p>
                                <p>{langType[lang].cars.car52}</p>
                                <p>{langType[lang].cars.car53}</p>
                                <p>{langType[lang].cars.car54}</p>
                            </div>

                        </div>
                        <div className="carBlock">
                            <h3>{langType[lang].cars.car6}</h3>
                            <div className="carImg1">
                                <img src={car6.img} className="img1CAR1" alt="car"/>
                            </div>
                            <div className={'textCont'}>
                                <p>{langType[lang].cars.car61}</p>
                                <p>{langType[lang].cars.car62}</p>
                                <p>{langType[lang].cars.car63}</p>
                                <p>{langType[lang].cars.car64}</p>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Cars;
