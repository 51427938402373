import React from "react";
import { bool } from "prop-types";
import { StyledMenu } from "./Menu.styled";
import "./Menu.scss";
import {langType} from "../../../../JDK/lang";
const Menu = ({ open, setOpen,lang, ...props }) => {
  const isHidden = open ? true : false;

  return (
    <StyledMenu open={open} aria-hidden={!isHidden} {...props}>
      <div className="anchorsContainer1">
        <a href="#services" onClick={() => setOpen(!open)}>{langType[lang].header.t1}</a>
        <a href="#Portfolio" onClick={() => setOpen(!open)}>{langType[lang].header.t2}</a>
        <a href="#workS" onClick={() => setOpen(!open)}>{langType[lang].header.t3}</a>
        <a href="#BackToUs1" onClick={() => setOpen(!open)}>{langType[lang].header.t4}</a>
        <a href="#Footer1" onClick={() => setOpen(!open)}>{langType[lang].header.t5}</a>
      </div>
    </StyledMenu>
  );
};

Menu.propTypes = {
  open: bool.isRequired,
};

export default Menu;
