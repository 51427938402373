import "./Main.scss";
import mainImg from "../../../assets/JDK/headerbg.png";
import Popup from "reactjs-popup";
// import "reactjs-popup/dist÷/index.css";
import PopupContent from "../../JDK/PopupJDK/Popup";
import {langType} from "../lang";

const Main = ({lang}) => {
  return (
    <div className="Main">
      <div className="mainDiv">
         <p>{langType[lang].main.t1}</p>
        <Popup
          trigger={<div className="mainButton">{langType[lang].popup.t3}</div>}
          position="center center"
          modal
        >
          <PopupContent lang={lang}/>
        </Popup>
      </div>
      <div className="mainDivImageContainer">
        <img src={mainImg} alt="main img desctopImg" className="desctopImg" />
        <img src={mainImg} alt="main img mobile" className="imgMobile" />
      </div>
    </div>
  );
};

export default Main;
