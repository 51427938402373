import "./Services.scss";
import ScrollableAnchor from "react-scrollable-anchor";
import Bg from '../../../assets/JDK/bgservices.png'
import {langType} from "../lang";

const Services = ({lang}) => {
    return (<div className="Services1">
        <ScrollableAnchor id={"services"}>
            <div className="ServicesBg1" style={{
                background: `url(${Bg})`,
            }}>
                <div className="ourServcies">
                    <h1>{langType[lang].services.t1H}</h1>
                </div>
                <div className="servicesList">
                    <div className="servicesBlock">
                        <div className="servicesContainer">
                            <h3>{langType[lang].services.t2}</h3>
                            <ul>
                                <li>{langType[lang].services.t5}</li>
                                <li>{langType[lang].services.t6}</li>
                                <li>{langType[lang].services.t7}</li>
                                <li>{langType[lang].services.t8}</li>
                                <li>{langType[lang].services.t9}</li>
                            </ul>
                        </div>
                        <div className="servicesContainer">
                            <h3>{langType[lang].services.t10H}</h3>
                            <ul>
                                <li>{langType[lang].services.t11}</li>
                                <li>{langType[lang].services.t111}</li>

                                <li>{langType[lang].services.t12}</li>
                                <li>{langType[lang].services.t13}</li>
                            </ul>
                        </div>
                    </div>
                    <div className="servicesBlock">
                        <div className="servicesContainer">
                            <h3>{langType[lang].services.t14H}</h3>
                            <ul>
                                <li>{langType[lang].services.t15}</li>
                                <li>{langType[lang].services.t16}</li>
                                <li>{langType[lang].services.t17}</li>
                                <li>{langType[lang].services.t18}</li>
                                <li>{langType[lang].services.t19}</li>
                            </ul>
                        </div>
                        <div className="servicesContainer">
                            <h3>{langType[lang].services.t20H}</h3>
                            <ul className='last-serv-ul'>
                                <li>
                                    {langType[lang].services.t21}
                                </li>
                                <li>
                                    {langType[lang].services.t22}
                                </li>
                                <li>
                                    {langType[lang].services.t23}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </ScrollableAnchor>
    </div>);
};

export default Services;
