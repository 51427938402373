import React from "react";
import "./dropdown.css";
import {langType} from "../lang";

const {useState, useEffect} = React;

const data = [{id: 0, label: "Русский"}, {id: 1, label: "English"}, {id: 2, label: "中国人"}];

const Dropdown = ({setLang, lang}) => {
    const [isOpen, setOpen] = useState(false);
    const [items, setItem] = useState(data);
    const [selectedItem, setSelectedItem] = useState(null);
    console.log(lang)
    const toggleDropdown = () => setOpen(!isOpen);

    let setLangItems = (selectedItem) => {
        if (selectedItem === 0) {
            setLang('ru')
        }
        if (selectedItem === 1) {
            setLang('en')
        }
        if (selectedItem === 2) {
            setLang('ch')
        }
    }
    const handleItemClick = (id) => {
        selectedItem === id ? setSelectedItem(null) : setSelectedItem(id);
        if (id == 0) {
            setLang('ru')
        }
        if (id == 1) {
            setLang('en')
        }
        if (id == 2) {
            setLang('ch')
        }
         setOpen(!isOpen);


    }

    return (
        <div className='dropdown'>
            <div className='dropdown-header' onClick={toggleDropdown}>
                {selectedItem ? items.find(item => item.id == selectedItem).label : langType[lang].dropdown.title}
                <i className={`fa fa-chevron-right icon ${isOpen && "open"}`}></i>
            </div>
            <div className={`dropdown-body ${isOpen && 'open'}`}>
                {items.map(item => (
                    <div className="dropdown-item" onClick={e => handleItemClick(e.target.id)} id={item.id}>
                        <span className={`dropdown-item-dot ${item.id == selectedItem && 'selected'}`}>• </span>
                        {item.label}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Dropdown