import "./Popup.scss";
import { langType } from "../lang";

const PopupContent = ({ lang }) => {
  const getHref = () => {
    if (lang === "ru") {
      return "http://ruform.jdk.by/";
    }
    if (lang === "en") {
      return "http://enform.jdk.by/";
    }
    if (lang === "ch") {
      return "http://zhform.jdk.by/";
    }
  };
  return (
    <div className="Popup">
      <div className="PopupContainer">
        <div className="PopupLinks">
          <h1>{langType[lang].popup.t1}</h1>
          <span className="textPopup">{langType[lang].popup.t2}</span>
          <div className="Links">
            <div>
            <div className="secondDivSmall">
              <span>
                <a
                  className="tel-href"
                  href={
                    window.location.href.includes("/customers")
                      ? "tel:+375 (29) 863-20-30"
                      : "tel:+375 (29) 863-20-30"
                  }
                >
                  {window.location.href.includes("/customers")
                    ? "+375 (29) 863-20-30"
                    : "+375 (29) 863-20-30"}
                </a>
              </span>
              <span className="hoverMail">jdk.orders@gmail.com</span>
              </div>
            </div>
            <div className="secondDiv">
              <div className="secondDivSmall">
                <span className="viber">
                  <a
                    href={
                      window.location.href.includes("/customers")
                        ? "viber://chat?number=%2B375298632030"
                        : "viber://chat?number=%2B375298632030"
                    }
                  >
                    Viber
                  </a>
                </span>
                <span className="tg">
                  <a
                    href={
                      window.location.href.includes("/customers")
                        ? "https://t.me/Manager_JDK"
                        : "https://t.me/Manager_JDK"
                    }
                  >
                    Telegram
                  </a>
                </span>
              </div>
              <div className="secondDivSmall">
                <span>
                  <a className="tel-href" href="https://www.wechat.com/ru/">
                    WeChat: Manager_JDK
                  </a>
                </span>
                <span className="whatsapp">
                  <a
                    href={
                      window.location.href.includes("/customers")
                        ? "https://wa.me/375298632030"
                        : "https://wa.me/375298632030"
                    }
                  >
                    Whatsapp
                  </a>
                </span>
              </div>
            </div>

            <div className="mainButton123">
              <a className="href-popup" href={getHref()}>
                <span>{langType[lang].popup.t3}</span>
              </a>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupContent;
